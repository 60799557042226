<style lang='scss' scoped>
.pof {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    height: 100vh;
    background-color: #fff;
    border-radius: 0 0 0 10px;
    box-sizing: border-box;
    padding-top: 30px;
    .listBox {
        width: 320px;
        margin: 20px;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
        .title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 10px;
        }
        .list {
            display: flex;
            flex-wrap: wrap;
            .item {
                margin-bottom: 10px;
                margin-right: 12px;
                width: 60px;
                height: 60px;
                text-align: center;
                line-height: 60px;
                border-radius: 5px;
                font-size: 12px;
                cursor: pointer;
                .a {
                    text-decoration: none;
                    &:hover {
                        color: #f00;
                    }
                }
            }
            .item:nth-child(4n) {
                margin-right: 0;
            }
        }
    }
}
.sq {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    cursor: pointer;
    img {
        transform: rotate(-90deg);
    }
}
.sqxz {
    transform: rotate(90deg) !important;
}
iframe {
    width: 100vw;
    height: 100vh;
    position: fixed;
}
.toplogo {
    width: 200px;
    height: auto;
    position: absolute;
    top: -20px;
    left: 50px;
}
</style>

<template>
    <div class='index'>
        <div class="pof" v-if="sqFlag">
            <img src="../assets/toplogo.png" alt="" class="toplogo">
            <div class="listBox" v-for="(item, index) in DataList" :key="index">
                <div class="title">
                    {{ item.title }}
                </div>
                <div class="list">
                    <div class="item" v-for="(list, index) in item.list" :key="index" :style="{'background':list.bgColor,'color':list.color}" @click="opurl(list)">
                        <span class="a">{{ list.name }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="sq">
            <img src="../assets/sq.png" alt="" @click="sqFlag = !sqFlag" :class="sqFlag?'sqxz':''">
        </div>
        <iframe :src="iframeSrc" frameborder="0"></iframe>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            sqFlag: true,
            DataList: [
                {
                    title: '大厅版面参考站',
                    list: [
                        {
                            name: '经典版',
                            link: 'demo1.ug-demo.com',
                            bgColor: '#ec89a5',
                            color: '#fff0bb'
                        },
                        {
                            name: '新版',
                            link: 'demo3.ug-demo.com',
                            bgColor: '#eef5ff',
                            color: '#006cff'
                        },
                        {
                            name: '',
                            link: '',
                            bgColor: '',
                            color: ''
                        },
                        {
                            name: '',
                            link: '',
                            bgColor: '',
                            color: ''
                        },
                        {
                            name: '小图标版',
                            link: 'demo2.ug-demo.com',
                            bgColor: '#22674b',
                            color: '#f9fc50'
                        },
                        {
                            name: '小图标版2',
                            link: 'demo4.ug-demo.com',
                            bgColor: '#392785',
                            color: '#fed71d'
                        },
                        {
                            name: '小图标版3',
                            link: 'demo-hall4.ug-demo.com',
                            bgColor: '#2b2727',
                            color: '#f0c059'
                        }
                    ]
                },
                {
                    title: '大厅色调参考站',
                    list: [
                        {
                            name: '桃红色',
                            link: 'demo-bigred.ug-demo.com',
                            bgColor: '#f59e8f',
                            color: '#ffe1c9'
                        },
                        {
                            name: '粉红色',
                            link: 'demo-pink.ug-demo.com',
                            bgColor: '#b94b6b',
                            color: '#fff0bb'
                        },
                        {
                            name: '玫红色',
                            link: 'demo-rosered.ug-demo.com',
                            bgColor: '#9d0347',
                            color: '#fff0bb'
                        },
                        {
                            name: '大红色',
                            link: 'demo-red.ug-demo.com',
                            bgColor: '#9c1524',
                            color: '#f5d469'
                        },
                        {
                            name: '浅蓝色',
                            link: 'demo-lightblue.ug-demo.com',
                            bgColor: '#eef5ff',
                            color: '#006cff'
                        },
                        {
                            name: '蓝色',
                            link: 'demo-blue.ug-demo.com',
                            bgColor: '#1b74e4',
                            color: '#fcfdfe'
                        },
                        {
                            name: '暗蓝色',
                            link: 'demo-darksblue.ug-demo.com',
                            bgColor: '#05309f',
                            color: '#fff0bb'
                        },
                        {
                            name: '深蓝色',
                            link: 'demo-darkblue.ug-demo.com',
                            bgColor: '#05309f',
                            color: '#fff0bb'
                        },
                        {
                            name: '黑蓝色',
                            link: 'demo-blackblue.ug-demo.com',
                            bgColor: '#0e131b',
                            color: '#005dfe'
                        },
                        {
                            name: '黑红色',
                            link: 'demo-blackred.ug-demo.com',
                            bgColor: '#313131',
                            color: '#e21823'
                        },
                        {
                            name: '黑黄色',
                            link: 'demo-blackyellow.ug-demo.com',
                            bgColor: '#262627',
                            color: '#f0b83f'
                        },
                        {
                            name: '黑白色',
                            link: 'demo-blackwhite.ug-demo.com',
                            bgColor: '#262627',
                            color: '#f0b83f'
                        },
                        {
                            name: '黑绿色',
                            link: 'demo-black.ug-demo.com',
                            bgColor: '#1a1a1a',
                            color: '#91e7b2'
                        },
                        {
                            name: '棕色',
                            link: 'demo-brown.ug-demo.com',
                            bgColor: '#a49485',
                            color: '#ffd49b'
                        },
                        {
                            name: '咖啡色',
                            link: 'demo-coffee.ug-demo.com',
                            bgColor: '#70552d',
                            color: '#ecb267'
                        },
                        {
                            name: '紫色',
                            link: 'demo-purple.ug-demo.com',
                            bgColor: '#392785',
                            color: '#f5c62a'
                        },
                        {
                            name: '紫黄色',
                            link: 'demo-purpleyellow.ug-demo.com',
                            bgColor: '#371584',
                            color: '#fed027'
                        },
                        {
                            name: '紫罗兰',
                            link: 'demo-ziluo.ug-demo.com',
                            bgColor: '#630963',
                            color: '#ffdc83'
                        },
                        {
                            name: '紫粉色',
                            link: 'demo-purplepink.ug-demo.com',
                            bgColor: '#2b0977',
                            color: '#d560ff'
                        },
                        {
                            name: '深绿色',
                            link: 'demo-darkgreen.ug-demo.com',
                            bgColor: '#1d533e',
                            color: '#f9fc50'
                        },
                        {
                            name: '灰绿色',
                            link: 'demo-graygreen.ug-demo.com',
                            bgColor: '#60826d',
                            color: '#f4e7cb'
                        },
                        {
                            name: '草绿色',
                            link: 'demo-grassgreen.ug-demo.com',
                            bgColor: '#067831',
                            color: '#fee001'
                        },
                        {
                            name: '浅绿色',
                            link: 'demo-lightgreen.ug-demo.com',
                            bgColor: '#3ba07d',
                            color: '#fffff8'
                        },
                        {
                            name: '橙色',
                            link: 'demo-orange.ug-demo.com',
                            bgColor: '#cc4d2a',
                            color: '#fffff9'
                        },
                        {
                            name: '绿白',
                            link: 'demo-greenwhite.ug-demo.com',
                            bgColor: '#007541',
                            color: '#fff'
                        },
                        {
                            name: '黄白',
                            link: 'demo-yellowhite.ug-demo.com',
                            bgColor: '#f0c80f',
                            color: '#fff'
                        },
                        {
                            name: '浅绿白',
                            link: 'demo-lightgreenwhite.ug-demo.com',
                            bgColor: '#00da0f',
                            color: '#fff'
                        },
                        {
                            name: '浅黄白',
                            link: 'demo-lightyellowwhite.ug-demo.com',
                            bgColor: '#c0ffb6',
                            color: '#fff'
                        },
                        {
                            name: '蓝白',
                            link: 'demo-bluewhite.ug-demo.com',
                            bgColor: '#21d2ff',
                            color: '#fff'
                        },
                    ]
                }
            ],
            iframeSrc: ''
        }
    },
    created() {
        this.iframeSrc = localStorage.getItem('iframeSrc') || 'https://demo1.ug-demo.com';
    },
    watch: {},
    methods: {
        opurl(item) {
            if (!item.link) return;
            localStorage.setItem('iframeSrc', 'https://' + item.link);
            this.iframeSrc = 'https://' + item.link;
        }
    },
};
</script>
